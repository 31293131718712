import React from "react"

import "@fontsource/montserrat" // Defaults to weight 400 with all styles included.

const Index = () => {
  return (
<html>

<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Welcome to Ping Page!</title>
</head>



<body class="bg-gray-100 min-h-screen">
    <div class="grid">
        <div class="max-w-lg mx-auto">
            <img class="mt-5 px-8 py-12 max-h-screen" src="logo.png" alt="Ping Page Logo" />
        </div>
        <div class="mx-auto">
            <h1 class="text-8xl font-montserrat font-bold text-gray-900 mt-5 mx-auto invisible md:visible">Coming Soon!
            </h1>
        </div>
    </div>
</body>

</html>
  )
}





export default Index;